import { Link } from 'react-router-dom'
export default function Header() {
  return (
    <header>
      <div className="container pt-4">
        <div className="row">
          <div className="col col-md-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 352 69.541"
              className="logo"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_41"
                    data-name="Rectangle 41"
                    width="60.244"
                    height="69.541"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_21"
                data-name="Group 21"
                transform="translate(-62.425 474.562)"
              >
                <g
                  id="Group_20"
                  data-name="Group 20"
                  transform="translate(62.425 -474.562)"
                >
                  <g
                    id="Group_12"
                    data-name="Group 12"
                    transform="translate(0 0)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_47"
                      data-name="Path 47"
                      d="M20.061,39.236c.008.125.022.251.022.376q0,3.45,0,6.9v.343c-.12.083-.23.173-.351.242-1.339.764-2.684,1.517-4.018,2.287-1.43.826-2.85,1.669-4.277,2.5-.929.542-1.862,1.076-2.794,1.614L3.938,56.218c-.171.1-.344.193-.532.3a2.781,2.781,0,0,1-.442-.167c-.9-.522-1.791-1.053-2.684-1.582-.077-.046-.149-.1-.217-.145C0,54.179-.026,20.989.035,19.9c.089-.166.269-.223.421-.311,1.085-.63,2.174-1.254,3.258-1.886,1.052-.614,2.1-1.242,3.151-1.851,1.275-.735,2.561-1.452,3.838-2.182q1.634-.935,3.26-1.884c1.115-.65,2.221-1.314,3.338-1.958,1.415-.816,2.842-1.611,4.257-2.427,1.133-.653,2.253-1.328,3.385-1.985.667-.387,1.347-.752,2.017-1.133.716-.408,1.428-.823,2.14-1.237.262-.152.52-.312.78-.468l.1-.021-.008,0,.36.012c.9.526,1.792,1.057,2.693,1.576.668.385,1.346.752,2.017,1.132.451.255.9.516,1.347.777.12.07.232.152.393.259-1.02.646-2.047,1.189-3.047,1.778s-1.989,1.151-2.984,1.726l-3.026,1.747c-.993.573-1.984,1.15-2.98,1.719-1.012.579-2.032,1.144-3.042,1.726-.992.573-1.975,1.163-2.965,1.74-1.006.587-2.016,1.167-3.024,1.75l-2.985,1.723Q11.217,21.1,9.7,21.976c-.991.573-1.989,1.134-2.974,1.729-.008.165-.021.306-.021.447q0,11.072,0,22.144c0,.125.01.251.015.376-.01.065-.006.119.082.116.176-.086.357-.161.526-.258q1.721-.989,3.439-1.986L13.6,42.91q2.372-1.368,4.743-2.738c.541-.313,1.079-.63,1.618-.945l.049.01Z"
                      transform="translate(0 -2.464)"
                      fill="#fff"
                    />
                    <path
                      id="Path_48"
                      data-name="Path 48"
                      d="M285.484,397.74c-.005-.161-.015-.323-.015-.484q0-3.261,0-6.523v-.455a1.09,1.09,0,0,1,.489-.375q1.978-1.148,3.96-2.287l5.034-2.906q1.35-.779,2.7-1.559c1.1-.639,2.2-1.286,3.3-1.921q2.169-1.25,4.345-2.486a.648.648,0,0,1,.141-.044c.12.051.093.153.1.234.008.161,0,.323,0,.485q0,26.28,0,52.56c0,.232-.005.463-.008.714l-3.125,1.819-.37.007c-1.09-.619-2.183-1.233-3.269-1.858-.7-.4-1.386-.828-2.083-1.235-.866-.506-1.737-1-2.606-1.506l-5.22-3.013-2.934-1.693q-1.677-.968-3.352-1.937c-.119-.069-.229-.154-.343-.232.037-.053.051-.093.079-.109.771-.457,1.539-.917,2.315-1.364,1.4-.8,2.8-1.6,4.2-2.4,1.128.648,2.231,1.281,3.332,1.916s2.2,1.275,3.307,1.909,2.184,1.28,3.331,1.914a2.439,2.439,0,0,0,.051-.272c.056-1.291.016-33.894-.045-34.357a2.676,2.676,0,0,0-.338.137c-.78.445-1.556.9-2.334,1.344l-3.354,1.935-2.793,1.613q-1.863,1.076-3.726,2.151c-.186.107-.379.2-.568.3l-.2-.022"
                      transform="translate(-272.076 -365.074)"
                      fill="#fff"
                    />
                    <path
                      id="Path_49"
                      data-name="Path 49"
                      d="M1032.116,215.085c.576.256,16.448,9.434,16.761,9.689v34.686c-.683.466-6.145,3.614-6.58,3.794-.109-.043-.085-.145-.088-.227-.007-.2,0-.4,0-.593q0-16.549,0-33.1v-.724c-.158-.1-.3-.2-.457-.287-1.183-.674-2.371-1.34-3.552-2.018-.747-.429-1.479-.882-2.224-1.315q-2.234-1.3-4.473-2.585l-3.26-1.884q-1.117-.645-2.234-1.29c-.152-.088-.3-.185-.473-.292a1.539,1.539,0,0,1,.188-.161c2.139-1.24,4.278-2.478,6.4-3.695"
                      transform="translate(-988.634 -207.347)"
                      fill="#fff"
                    />
                    <path
                      id="Path_50"
                      data-name="Path 50"
                      d="M1117.085,592.669l4.194,2.419c.682.394,1.36.8,2.05,1.178a.487.487,0,0,1,.3.515c-.012.143,0,.287,0,.431q0,16.627,0,33.253c0,.233-.016.466-.024.7l-.058.09-1.016.59-3.488,2.016q-.974.563-1.95,1.122l-.123.011c-.012-.214-.034-.429-.034-.643q0-20.525,0-41.051c0-.214.029-.427.044-.641l.108.01"
                      transform="translate(-1076.746 -571.336)"
                      fill="#fff"
                    />
                    <path
                      id="Path_51"
                      data-name="Path 51"
                      d="M833.479.111,833.119.1c.167-.131.182-.131.36.012"
                      transform="translate(-803.144 -0.002)"
                      fill="#fff"
                    />
                    <path
                      id="Path_52"
                      data-name="Path 52"
                      d="M832.743,1929.969l.37-.007c-.186.137-.188.137-.37.007"
                      transform="translate(-802.782 -1860.524)"
                      fill="#fff"
                    />
                    <path
                      id="Path_53"
                      data-name="Path 53"
                      d="M372.669,907.915l.2.021c-.138.108-.141.108-.2-.021"
                      transform="translate(-359.261 -875.249)"
                      fill="#fff"
                    />
                    <path
                      id="Path_54"
                      data-name="Path 54"
                      d="M830.606,2.5l-.1.022a.14.14,0,0,1,.1-.022"
                      transform="translate(-800.622 -2.408)"
                      fill="#fff"
                    />
                    <path
                      id="Path_55"
                      data-name="Path 55"
                      d="M1118.308,592.669l-.108-.01.108.01"
                      transform="translate(-1077.968 -571.336)"
                      fill="#fff"
                    />
                    <path
                      id="Path_56"
                      data-name="Path 56"
                      d="M554.929,1021.788h-.05l-.049-.01.1.009"
                      transform="translate(-534.868 -985.017)"
                      fill="#fff"
                    />
                    <path
                      id="Path_57"
                      data-name="Path 57"
                      d="M186.754,1228.855c-.087,0-.092-.051-.082-.116l.082.116"
                      transform="translate(-179.952 -1184.531)"
                      fill="#fff"
                    />
                    <path
                      id="Path_58"
                      data-name="Path 58"
                      d="M1300.678,1662.96l.059-.091-.059.091"
                      transform="translate(-1253.881 -1603.042)"
                      fill="#fff"
                    />
                    <path
                      id="Path_59"
                      data-name="Path 59"
                      d="M1117.9,1769.007l.123-.011c-.035.065-.075.083-.123.011"
                      transform="translate(-1077.682 -1705.35)"
                      fill="#fff"
                    />
                  </g>
                </g>
                <g
                  id="Group_19"
                  data-name="Group 19"
                  transform="translate(136.825 -450.14)"
                >
                  <g
                    id="Group_18"
                    data-name="Group 18"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_61"
                      data-name="Path 61"
                      d="M1961.7,201.086a3.157,3.157,0,0,1,.055,2.054,4.3,4.3,0,0,1-.7.161c-.441.029-.884.018-1.326.018h-89.337c-.491,0-.983,0-1.473-.026a3.1,3.1,0,0,1-.571-.14,2.6,2.6,0,0,1-.079-1.792.875.875,0,0,1,.746-.286c.442-.011.885-.009,1.327-.009h89.337c.636,0,1.271.012,2.023.019"
                      transform="translate(-1684.301 -181.279)"
                      fill="#fff"
                    />
                    <path
                      id="Path_62"
                      data-name="Path 62"
                      d="M93.54,201.088a3.254,3.254,0,0,1,.176,1.97,4.24,4.24,0,0,1-.738.2c-.44.032-.884.02-1.326.02q-44.727,0-89.454,0a5.827,5.827,0,0,1-1.925-.141,2.87,2.87,0,0,1-.07-1.921,3.828,3.828,0,0,1,.681-.169c.44-.03.884-.019,1.326-.019q44.727,0,89.454,0a11.262,11.262,0,0,1,1.877.067"
                      transform="translate(-0.066 -181.233)"
                      fill="#fff"
                    />
                    <path
                      id="Path_63"
                      data-name="Path 63"
                      d="M1486.7,12.3l-1.165-.053c-.326-.518-.64-1.011-.949-1.509s-.6-1.012-.924-1.506a5.421,5.421,0,0,0-.953-1.381c-.342.088-.309.317-.311.492-.014,1.276-.007,2.553-.021,3.829a1.77,1.77,0,0,1-.24,1.2,3.84,3.84,0,0,1-1.847.033,1.314,1.314,0,0,1-.309-1.056c-.009-1.522-.007-3.044-.007-4.566,0-1.227-.006-2.455,0-3.682,0-.437.044-.873.07-1.364a3.887,3.887,0,0,1,.714-.2c.387-.031.779-.009,1.2-.009.25.34.5.635.7.961.921,1.508,1.824,3.027,2.743,4.537.2.329.438.636.638.925.422-.044.534-.341.68-.584.908-1.516,1.8-3.039,2.714-4.554a3.654,3.654,0,0,1,.888-1.22,3.812,3.812,0,0,1,1.764.048,4.059,4.059,0,0,1,.19.811c.034,1.03.035,2.061.043,3.092.015,1.816.033,3.633.037,5.449a2.388,2.388,0,0,1-.166,1.29,3.459,3.459,0,0,1-2.072.079,3.95,3.95,0,0,1-.178-.793c-.028-1.178-.022-2.356-.036-3.534-.005-.437-.033-.873-.054-1.391-.2.08-.365.093-.417.176-.622,1-1.226,2.011-1.838,3.017-.279.458-.565.912-.9,1.459"
                      transform="translate(-1334.332 -2.268)"
                      fill="#fff"
                    />
                    <path
                      id="Path_64"
                      data-name="Path 64"
                      d="M1641.953,13.32a9.863,9.863,0,0,1-.186-2.92c-.045-.925.033-1.856-.054-2.754a.408.408,0,0,0-.533.242c-.611,1.008-1.208,2.024-1.818,3.033-.252.417-.523.824-.8,1.264l-1.006.057a1.981,1.981,0,0,1-.348-.324c-.677-1.083-1.334-2.178-2.018-3.256-.2-.322-.326-.723-.707-.914-.3.424-.293.425-.308,2.655a12.194,12.194,0,0,1-.124,2.808,3.389,3.389,0,0,1-2.055.16,3.34,3.34,0,0,1-.2-.687c-.035-.489-.026-.982-.026-1.473q0-3.537,0-7.075a3.884,3.884,0,0,1,.151-1.492,3.337,3.337,0,0,1,2.05-.055c.43.7.868,1.405,1.3,2.116s.854,1.436,1.3,2.142a13.283,13.283,0,0,0,1.326,2.047c.366-.026.476-.256.6-.458.932-1.56,1.858-3.124,2.791-4.684a4.015,4.015,0,0,1,.883-1.219c.262-.021.554-.063.845-.061s.58.05.923.083a3.248,3.248,0,0,1,.182.7c.028.884.027,1.768.03,2.652q.011,2.948.013,5.9a3.8,3.8,0,0,1-.159,1.5,3.829,3.829,0,0,1-2.046.022"
                      transform="translate(-1471.179 -2.215)"
                      fill="#fff"
                    />
                    <path
                      id="Path_65"
                      data-name="Path 65"
                      d="M1912.52,7.437c-.194,1.947.047,3.862-.157,5.793a4.506,4.506,0,0,1-2.191.1,1.177,1.177,0,0,1-.286-.99c-.007-1.769-.005-3.537-.006-5.306,0-1.13,0-2.26,0-3.39a1.585,1.585,0,0,1,.236-1.077,3.968,3.968,0,0,1,1.858-.069c.24.256.521.526.767.823,1.283,1.553,2.556,3.114,3.835,4.669.182.222.38.431.624.706.118-.189.241-.3.243-.4.019-1.326.014-2.652.03-3.979a4.72,4.72,0,0,1,.148-1.684,2.9,2.9,0,0,1,.632-.171,3.151,3.151,0,0,1,1.526.148,2.872,2.872,0,0,1,.154.641c.023.982.021,1.965.022,2.947,0,1.916,0,3.832,0,5.748a2.537,2.537,0,0,1-.2,1.363,4.318,4.318,0,0,1-1.913.01c-.322-.375-.679-.778-1.021-1.193-1.157-1.4-2.3-2.812-3.466-4.209-.176-.212-.31-.516-.834-.474"
                      transform="translate(-1721.928 -2.184)"
                      fill="#fff"
                    />
                    <path
                      id="Path_66"
                      data-name="Path 66"
                      d="M265.709,13.381c-.321.031-.561.074-.8.074-.341,0-.681-.03-1.037-.048-.981-.981-1.784-2.1-2.668-3.142-.842-1-1.643-2.029-2.475-2.976a.509.509,0,0,0-.332.573c-.009,1.422,0,2.844-.017,4.266a1.85,1.85,0,0,1-.229,1.216,4.509,4.509,0,0,1-2.049.029,1.214,1.214,0,0,1-.275-1c-.008-1.863-.006-3.727-.006-5.591,0-1.03-.008-2.06.007-3.089,0-.333.07-.665.107-1a4.609,4.609,0,0,1,1.938-.2c.291.308.574.574.819.871,1.249,1.512,2.488,3.033,3.734,4.549.214.261.445.508.72.821.115-.177.241-.281.243-.386.02-1.324.015-2.648.03-3.972a5.093,5.093,0,0,1,.135-1.685,2.7,2.7,0,0,1,.626-.181,3.167,3.167,0,0,1,1.529.141,3.587,3.587,0,0,1,.173.628,9.846,9.846,0,0,1,.026,1.029c0,2.452.006,4.9,0,7.356a4.037,4.037,0,0,1-.194,1.716"
                      transform="translate(-230.642 -2.239)"
                      fill="#fff"
                    />
                    <path
                      id="Path_67"
                      data-name="Path 67"
                      d="M2615.187,8.529a.442.442,0,0,0,.272-.463c.012-.54.017-1.08.02-1.621,0-.933,0-1.867.008-2.8a1.43,1.43,0,0,1,.3-1.073,5.5,5.5,0,0,1,1.967-.047,3.178,3.178,0,0,1,.2.716c.028.834.023,1.67.024,2.5q0,3.021,0,6.043a4.686,4.686,0,0,1-.1,1.4c-.144.093-.221.187-.3.189-.535.013-1.071.007-1.689.007-.327-.376-.688-.773-1.03-1.187-1.189-1.439-2.369-2.886-3.557-4.326-.178-.216-.335-.481-.656-.485a.966.966,0,0,0-.238.839c-.008,1.179,0,2.358-.01,3.537a3.2,3.2,0,0,1-.187,1.512,3.853,3.853,0,0,1-2.149.062,3.913,3.913,0,0,1-.143-.722q-.014-4.642,0-9.285a3.921,3.921,0,0,1,.132-.706,3.945,3.945,0,0,1,1.978-.065c.217.237.458.479.674.741,1.25,1.516,2.491,3.04,3.743,4.554.213.258.372.589.74.677"
                      transform="translate(-2351.268 -2.218)"
                      fill="#fff"
                    />
                    <path
                      id="Path_68"
                      data-name="Path 68"
                      d="M939.039,5.636a5.509,5.509,0,0,1-3.3,5.193,6.366,6.366,0,0,1-5.434.02A5.268,5.268,0,0,1,927,5.863a5.287,5.287,0,0,1,3.2-5.2,6.322,6.322,0,0,1,5.565-.024,5.5,5.5,0,0,1,3.282,5m-9.436.006a3.323,3.323,0,0,0,2.123,3.349,3.825,3.825,0,0,0,1.733.176,3.246,3.246,0,0,0,2.925-2.986A3.368,3.368,0,0,0,934.2,2.446c-2.942-.852-4.659,1.243-4.6,3.2"
                      transform="translate(-835.769 -0.001)"
                      fill="#fff"
                    />
                    <path
                      id="Path_69"
                      data-name="Path 69"
                      d="M120.575.279a6.644,6.644,0,0,1,2.579.54,5.445,5.445,0,0,1,3.393,5.236,5.411,5.411,0,0,1-3.538,5.139,6.589,6.589,0,0,1-5.031-.03,5.465,5.465,0,0,1-3.457-5.191A5.49,5.49,0,0,1,117.988.786a7,7,0,0,1,2.586-.508m-.055,2.234a3.259,3.259,0,0,0-3.378,3.445,3.273,3.273,0,0,0,3.334,3.49,3.35,3.35,0,0,0,3.477-3.518,3.325,3.325,0,0,0-3.433-3.416"
                      transform="translate(-103.251 -0.251)"
                      fill="#fff"
                    />
                    <path
                      id="Path_70"
                      data-name="Path 70"
                      d="M1350.339,6.486a5.2,5.2,0,0,1-3.257,4.481,6.354,6.354,0,0,1-5.433.016,5.275,5.275,0,0,1-3.311-5.134A5.292,5.292,0,0,1,1341.673.73,6.448,6.448,0,0,1,1346.7.6c2.294.85,3.672,2.885,3.641,5.889m-2.614-.715c0-.025,0-.27-.032-.512a3.189,3.189,0,0,0-2.464-2.767,3.4,3.4,0,0,0-3.734,1.38,3.647,3.647,0,0,0-.31,3.372,2.962,2.962,0,0,0,1.919,1.887,3.369,3.369,0,0,0,4.622-3.361"
                      transform="translate(-1206.631 -0.137)"
                      fill="#fff"
                    />
                    <path
                      id="Path_71"
                      data-name="Path 71"
                      d="M1068.148,2.714a4.226,4.226,0,0,1,.67-.1c1.424-.008,2.848,0,4.272,0a5.227,5.227,0,0,1,2.008.411,3.848,3.848,0,0,1,1.05,6.441c-.267.2-.516.428-.785.652a11.758,11.758,0,0,0,1.043,1.665,4.5,4.5,0,0,1,.953,1.684,7.07,7.07,0,0,1-2.341.045,6,6,0,0,1-1.123-1.4c-.35-.471-.686-.952-.991-1.376a3.535,3.535,0,0,0-2.277.02c-.339.808.012,1.728-.284,2.636a3.745,3.745,0,0,1-2.224.079c-.279-.945-.3-1.972-.229-9.279a2.175,2.175,0,0,1,.257-1.473m2.447,2.094a5.9,5.9,0,0,0-.105.786c.008.83.034,1.659.066,2.488,0,.081.1.159.148.239a6.428,6.428,0,0,0,2.449.1,4.153,4.153,0,0,0,.563-.165,1.661,1.661,0,0,0,1.092-1.279,1.787,1.787,0,0,0-1.572-2.222,7.871,7.871,0,0,0-2.641.056"
                      transform="translate(-962.766 -2.35)"
                      fill="#fff"
                    />
                    <path
                      id="Path_72"
                      data-name="Path 72"
                      d="M483.451,2.694a7.324,7.324,0,0,1,.813-.1c1.374-.008,2.749,0,4.124,0a5.343,5.343,0,0,1,2.149.439,3.835,3.835,0,0,1,1.052,6.361c-.25.228-.523.431-.834.686a7.324,7.324,0,0,0,.994,1.618,8.864,8.864,0,0,1,1,1.6c-.734.331-.982.343-2.513.121l-1.984-2.742a5.543,5.543,0,0,0-2.171-.078,6.919,6.919,0,0,0-.194.871,9.456,9.456,0,0,1-.14,1.926,6.509,6.509,0,0,1-2.231.143,3.182,3.182,0,0,1-.211-.733c-.025-1.129-.022-2.258-.023-3.387q0-2.651,0-5.3a3.773,3.773,0,0,1,.166-1.422m2.554,2.08a14.178,14.178,0,0,0-.114,2.921.768.768,0,0,0,.338.705,7.225,7.225,0,0,0,2.09.032,3.136,3.136,0,0,0,.846-.223,1.577,1.577,0,0,0,.96-1.035,1.788,1.788,0,0,0-1.42-2.424,8.1,8.1,0,0,0-2.7.024"
                      transform="translate(-435.723 -2.334)"
                      fill="#fff"
                    />
                    <path
                      id="Path_73"
                      data-name="Path 73"
                      d="M2478.332,5.923a5.444,5.444,0,0,1-3.85,5.345,6.8,6.8,0,0,1-4.478-.077,5.513,5.513,0,0,1-3.653-5.345,5.54,5.54,0,0,1,3.656-5.2,6.58,6.58,0,0,1,4.617-.026,5.428,5.428,0,0,1,3.708,5.3M2472.4,2.457a3.275,3.275,0,0,0-3.464,3.376,3.414,3.414,0,1,0,6.824.106,3.266,3.266,0,0,0-3.36-3.483"
                      transform="translate(-2223.636 -0.196)"
                      fill="#fff"
                    />
                    <path
                      id="Path_74"
                      data-name="Path 74"
                      d="M1785.917,2.5a2.406,2.406,0,0,1,.225,1.366c.01,1.325,0,2.651.01,3.976a10.777,10.777,0,0,0,.088,1.467,2.093,2.093,0,0,0,2.286,2,4.469,4.469,0,0,0,.587-.035,1.931,1.931,0,0,0,1.577-1.173A3.928,3.928,0,0,0,1791,8.384c0-1.424,0-2.847.006-4.271,0-.485.034-.969.053-1.488a3.307,3.307,0,0,1,2.344-.1,3.939,3.939,0,0,1,.145.729c.01,1.816.018,3.632-.007,5.448a6.586,6.586,0,0,1-.2,1.455,4.073,4.073,0,0,1-3.131,3.177,6.4,6.4,0,0,1-2.917.082,4.25,4.25,0,0,1-3.6-3.6,5.7,5.7,0,0,1-.091-1.025q-.014-2.724,0-5.448a5.789,5.789,0,0,1,.079-.686c.843-.336.843-.336,2.241-.156"
                      transform="translate(-1608.069 -2.128)"
                      fill="#fff"
                    />
                    <path
                      id="Path_75"
                      data-name="Path 75"
                      d="M2198.234,2.621a7.826,7.826,0,0,1,2.393-.117,5.908,5.908,0,0,1,.483.746q2.084,4.63,4.145,9.27a5.706,5.706,0,0,1,.208.676,3.6,3.6,0,0,1-2.378.145c-.518-.561-.594-1.4-1.063-2.03-.083-.05-.162-.138-.248-.146-1.565-.129-3.13-.062-4.744-.049l-1.116,2.245a7.914,7.914,0,0,1-2.15.044.463.463,0,0,1-.112-.6c.08-.231.181-.456.28-.679q1.886-4.234,3.778-8.467c.158-.354.348-.694.524-1.041m2.7,6.315a1.693,1.693,0,0,0-.035-.47q-.568-1.417-1.172-2.821c-.041-.094-.209-.132-.37-.227-.485.953-.828,1.91-1.222,2.845a1.388,1.388,0,0,0-.1.422,1.45,1.45,0,0,0,.116.383,6.617,6.617,0,0,0,2.786-.133"
                      transform="translate(-1977.719 -2.227)"
                      fill="#fff"
                    />
                    <path
                      id="Path_76"
                      data-name="Path 76"
                      d="M600.492,13.428a3.339,3.339,0,0,1-.592.116,6.247,6.247,0,0,1-1.771-.09c-.544-.587-.613-1.433-1.135-2.131a8.608,8.608,0,0,0-2.407-.115,18.41,18.41,0,0,0-2.543.054l-1.081,2.159a4.3,4.3,0,0,1-1.548.126,5.349,5.349,0,0,1-.684-.082.9.9,0,0,1,.085-.882q.647-1.484,1.307-2.962,1.288-2.89,2.584-5.776c.179-.4.392-.779.592-1.173.4-.034.738-.084,1.076-.086.437,0,.873.033,1.336.054a10.593,10.593,0,0,1,1.066,2.111c.337.709.643,1.432.962,2.149s.639,1.434.96,2.15.66,1.425.958,2.15a7.617,7.617,0,0,1,.835,2.228m-4.474-4.372a16,16,0,0,0-1.208-3.207c-.046-.113-.176-.192-.261-.281-.118.077-.239.111-.268.183q-.614,1.493-1.2,3c-.029.074.035.183.065.315a5.348,5.348,0,0,0,2.15.161,3.207,3.207,0,0,0,.723-.168"
                      transform="translate(-530.715 -2.331)"
                      fill="#fff"
                    />
                    <path
                      id="Path_77"
                      data-name="Path 77"
                      d="M2737.8,1.286a4.088,4.088,0,0,1-.583,1.5,1.155,1.155,0,0,1-.94-.121,6.182,6.182,0,0,0-2.3-.449,2.869,2.869,0,0,0-1.278.3,1.024,1.024,0,0,0-.616.931.873.873,0,0,0,.553.807,10.018,10.018,0,0,0,1.24.45c.565.164,1.147.27,1.711.439a7.782,7.782,0,0,1,1.233.476,2.861,2.861,0,0,1,.643,4.7,3.784,3.784,0,0,1-1.657.924,7.985,7.985,0,0,1-6.12-.746,1.911,1.911,0,0,1-.259-.223,3.16,3.16,0,0,1,.66-1.651,1.047,1.047,0,0,1,.9.2,7.466,7.466,0,0,0,2.259.632,3.668,3.668,0,0,0,1.882-.22.971.971,0,0,0,.663-.885.878.878,0,0,0-.506-.834,5.3,5.3,0,0,0-.953-.377c-.612-.179-1.238-.309-1.848-.494a9.882,9.882,0,0,1-1.376-.511,2.9,2.9,0,0,1-.7-4.713,4.115,4.115,0,0,1,1.222-.795,7.168,7.168,0,0,1,5.853.293.834.834,0,0,1,.215.195,1.1,1.1,0,0,1,.1.17"
                      transform="translate(-2460.83 -0.124)"
                      fill="#fff"
                    />
                    <path
                      id="Path_78"
                      data-name="Path 78"
                      d="M1234.007,2.114a3.537,3.537,0,0,1-1.372,1.374,7.128,7.128,0,0,1-.751-.414,3.642,3.642,0,0,0-2.659-.608,3.231,3.231,0,0,0-2.887,2.882,3.8,3.8,0,0,0,.2,1.883,3.171,3.171,0,0,0,2.77,2.1,3.511,3.511,0,0,0,2.64-.674c.19-.147.4-.271.61-.415a4.074,4.074,0,0,1,1.386,1.245.739.739,0,0,1-.318.687,5.867,5.867,0,0,1-4.223,1.4,6.107,6.107,0,0,1-2.151-.445,5.5,5.5,0,0,1-3.562-5.261,5.483,5.483,0,0,1,3.6-5.233,6.427,6.427,0,0,1,5.015.111,3.277,3.277,0,0,1,1.705,1.361"
                      transform="translate(-1103.263 -0.168)"
                      fill="#fff"
                    />
                    <path
                      id="Path_79"
                      data-name="Path 79"
                      d="M722.953,3.508c-.3-.195-.574-.388-.862-.564a3.706,3.706,0,0,0-3.124-.326,3.35,3.35,0,0,0-2.216,3.73,3.248,3.248,0,0,0,2.8,2.975,3.618,3.618,0,0,0,2.793-.634c.192-.146.4-.275.62-.43a4.03,4.03,0,0,1,1.4,1.214.7.7,0,0,1-.286.689,5.845,5.845,0,0,1-4.213,1.428,6.132,6.132,0,0,1-2.153-.434,5.5,5.5,0,0,1-3.594-5.383A5.5,5.5,0,0,1,717.813.6a6.5,6.5,0,0,1,4.473-.028,3.988,3.988,0,0,1,2.149,1.514,3.035,3.035,0,0,1-1.482,1.417"
                      transform="translate(-643.836 -0.174)"
                      fill="#fff"
                    />
                    <path
                      id="Path_80"
                      data-name="Path 80"
                      d="M2094.4,2.185a4.412,4.412,0,0,1-1.35,1.316c-.3-.184-.583-.355-.862-.537a3.879,3.879,0,0,0-3.118-.418,1.367,1.367,0,0,0-.142.041,3.435,3.435,0,0,0-2.184,3.766,3.264,3.264,0,0,0,2.84,2.942,3.589,3.589,0,0,0,2.666-.579c.235-.171.481-.325.737-.5a4.107,4.107,0,0,1,1.37,1.252.747.747,0,0,1-.323.69,5.871,5.871,0,0,1-4.228,1.392,6.277,6.277,0,0,1-2.152-.449,5.446,5.446,0,0,1-3.54-5.13A5.417,5.417,0,0,1,2087.6.665a6.286,6.286,0,0,1,6.163.67,1.226,1.226,0,0,1,.636.849"
                      transform="translate(-1879.013 -0.142)"
                      fill="#fff"
                    />
                    <path
                      id="Path_81"
                      data-name="Path 81"
                      d="M8.852,8.121a4.541,4.541,0,0,1,1.43,1.3.844.844,0,0,1-.376.68,5.6,5.6,0,0,1-3.357,1.35,6.338,6.338,0,0,1-4.077-.976A5.664,5.664,0,0,1,2.718.961,6.368,6.368,0,0,1,8.8.735,2.923,2.923,0,0,1,10.339,2.07,4.209,4.209,0,0,1,9,3.322c-.466.022-.688-.28-.971-.457a3.755,3.755,0,0,0-3.121-.376A3.292,3.292,0,0,0,2.633,6.034a3.209,3.209,0,0,0,2.6,3.144A3.571,3.571,0,0,0,8.18,8.605c.194-.146.394-.285.671-.485"
                      transform="translate(0 -0.065)"
                      fill="#fff"
                    />
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M378.165,13.562a5.451,5.451,0,0,1-2.049.041,1.045,1.045,0,0,1-.314-.968c-.008-1.867-.005-3.734-.008-5.6,0-.491,0-.983-.018-1.473a4.552,4.552,0,0,0-.112-.615c-1.049-.369-2.166.032-3.222-.3a2.748,2.748,0,0,1-.04-1.774,2.42,2.42,0,0,1,.569-.149q4.127-.014,8.253,0a2.031,2.031,0,0,1,.45.112,2.021,2.021,0,0,1-.023,1.831c-.981.265-1.981-.01-3.017.225a2.421,2.421,0,0,0-.224.621c-.043.488-.035.981-.036,1.472,0,1.671,0,3.341-.006,5.012a3.257,3.257,0,0,1-.2,1.569"
                      transform="translate(-335.638 -2.448)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M2312.81,4.789c-1.035-.288-2.09.04-3.106-.262a2,2,0,0,1,.047-1.863,6.389,6.389,0,0,1,.734-.083q3.981,0,7.962.02a2.247,2.247,0,0,1,.471.117A2.481,2.481,0,0,1,2319,4.46a3.429,3.429,0,0,1-.675.18c-.49.032-.983.008-1.473.026-.338.012-.675.057-.947.082a1.158,1.158,0,0,0-.3,1.018c-.009,1.966,0,3.932-.009,5.9,0,.536-.023,1.072-.032,1.478a.743.743,0,0,1-.725.394,4.108,4.108,0,0,1-1.59-.114,1.923,1.923,0,0,1-.231-1.289c-.01-1.917,0-3.834-.009-5.751a3.634,3.634,0,0,0-.194-1.594"
                      transform="translate(-2082.22 -2.326)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1345.555,180.772c.774-1.528,1-3.109,1.669-4.46a1.286,1.286,0,0,1,1.3-.042c.037.117.1.215.087.293a69.08,69.08,0,0,1-2.2,6.59,2.721,2.721,0,0,1-1.677.017,12.584,12.584,0,0,1-.784-2.082,10.681,10.681,0,0,0-.746-2c-.119.018-.2.009-.211.036-.734,1.23-.867,2.694-1.551,3.992a2.562,2.562,0,0,1-1.737.056c-.758-1.8-1.255-3.7-1.9-5.54-.143-.409-.229-.839-.338-1.246a1.517,1.517,0,0,1,1.508-.145c.734,1.3.874,2.863,1.591,4.25.15-.109.32-.165.36-.271.293-.78.558-1.569.834-2.355.178-.506.359-1.011.522-1.468a1.6,1.6,0,0,1,1.535-.172,5.1,5.1,0,0,1,.366.716c.332.974.631,1.959.959,2.934a9.814,9.814,0,0,0,.416.9"
                      transform="translate(-1205.843 -158.778)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M827.778,4.944c-1.043-.309-2.1.058-3.145-.289a2.665,2.665,0,0,1-.042-1.739,2.047,2.047,0,0,1,.546-.162q4.114-.015,8.229,0a1.518,1.518,0,0,1,.4.108,2.422,2.422,0,0,1,.1,1.763,3.316,3.316,0,0,1-.658.166c-.537.03-1.078.008-1.616.028-.288.011-.573.066-.816.1a1.905,1.905,0,0,0-.265,1.323c-.01,1.862,0,3.723-.007,5.585,0,.535-.029,1.071-.046,1.651a3.44,3.44,0,0,1-.724.207c-.484.024-.972,0-1.369-.009a1.082,1.082,0,0,1-.368-1.011c-.008-1.813,0-3.625-.009-5.438,0-.588,0-1.176-.032-1.762a1.931,1.931,0,0,0-.174-.516"
                      transform="translate(-743.33 -2.476)"
                      fill="#fff"
                    />
                    <path
                      id="Path_86"
                      data-name="Path 86"
                      d="M1011.057,183.271h-1.216c-.338-.394-.691-.8-1.036-1.21-.379-.452-.735-.923-1.128-1.363a4.986,4.986,0,0,0-1.132-1.194,1.735,1.735,0,0,0-.215,1.155c-.018.442.007.885-.009,1.327-.014.386-.06.771-.089,1.123a1.491,1.491,0,0,1-1.352.143,1.265,1.265,0,0,1-.189-.354,4.117,4.117,0,0,1-.054-.733q-.006-2.433,0-4.866c0-.289.044-.577.065-.827a1.561,1.561,0,0,1,1.418-.161l3.189,3.8c.13-.171.267-.267.27-.368.025-.786.019-1.572.034-2.358a1.613,1.613,0,0,1,.217-1.048,1.188,1.188,0,0,1,1.16,0c.069.117.159.2.161.282q.04,3.019.057,6.038a2.763,2.763,0,0,1-.153.614"
                      transform="translate(-905.768 -158.848)"
                      fill="#fff"
                    />
                    <path
                      id="Path_87"
                      data-name="Path 87"
                      d="M1500.816,181.864a3.853,3.853,0,0,1-4.043-2.809,3.614,3.614,0,0,1,1.782-4.122,4.166,4.166,0,0,1,3.951-.072,3.669,3.669,0,0,1-.6,6.811,8.769,8.769,0,0,1-1.093.192m1.992-3.569a2.219,2.219,0,1,0-4.426-.224,2.172,2.172,0,0,0,2.106,2.351,2.149,2.149,0,0,0,2.321-2.127"
                      transform="translate(-1349.367 -157.236)"
                      fill="#fff"
                    />
                    <path
                      id="Path_88"
                      data-name="Path 88"
                      d="M1634.279,180.919l1.3,2.083c-.175.094-.286.2-.4.207-.339.024-.68.008-1.016.008-.682-.488-.929-1.3-1.516-1.814a1.885,1.885,0,0,0-1.461.053c-.057.489-.117,1.012-.178,1.545a1.425,1.425,0,0,1-1.41.1,1.382,1.382,0,0,1-.147-.413q-.024-2.937-.02-5.874a2.852,2.852,0,0,1,.145-.606,3.869,3.869,0,0,1,.707-.143c.881-.012,1.764-.028,2.643.02a3.691,3.691,0,0,1,1.274.294,2.474,2.474,0,0,1,.94,3.819,5.839,5.839,0,0,1-.859.718m-2.968-3.424a2.892,2.892,0,0,0-.01,2.306,3.221,3.221,0,0,0,1.78-.017,1.173,1.173,0,0,0,.014-2.262,3.177,3.177,0,0,0-1.785-.028"
                      transform="translate(-1469.075 -158.729)"
                      fill="#fff"
                    />
                    <path
                      id="Path_89"
                      data-name="Path 89"
                      d="M2038.922,2.587a5.007,5.007,0,0,1,2.173,0,2.9,2.9,0,0,1,.191.662c.031.638.025,1.277.025,1.916,0,2.212.005,4.424,0,6.635a3.289,3.289,0,0,1-.172,1.508,4.962,4.962,0,0,1-2.212.054,3.988,3.988,0,0,1-.186-.819c-.023-1.228-.015-2.457-.016-3.686,0-1.622-.005-3.244,0-4.866a2.8,2.8,0,0,1,.2-1.4"
                      transform="translate(-1838.089 -2.224)"
                      fill="#fff"
                    />
                    <path
                      id="Path_90"
                      data-name="Path 90"
                      d="M1749.36,179.216a4.33,4.33,0,0,0,.915,1.4c.292.393.618.76.906,1.157a3.543,3.543,0,0,1,.861,1.344,2.8,2.8,0,0,1-1.546.11c-.9-.69-1.408-1.812-2.329-2.548a1.953,1.953,0,0,0-.416.21c-.181.159-.336.35-.523.551l-.143,1.584a1.386,1.386,0,0,1-1.389.134,1.755,1.755,0,0,1-.232-1.15c-.083-3.709-.05-4.688.189-5.71a1.414,1.414,0,0,1,1.316-.055,3.326,3.326,0,0,1,.167.647c.032.488.013.979.032,1.468a4.322,4.322,0,0,0,.112.591c.552-.1.777-.51,1.076-.8.35-.342.674-.711,1.018-1.059.307-.312.625-.613.885-.866a2.049,2.049,0,0,1,1.5.041,2.4,2.4,0,0,1-.763,1.127c-.255.3-.537.571-.8.863-.291.325-.572.658-.834.961"
                      transform="translate(-1573.655 -158.774)"
                      fill="#fff"
                    />
                    <path
                      id="Path_91"
                      data-name="Path 91"
                      d="M1130.913,177.261a1.131,1.131,0,0,0-.091,1.188c.1.065.172.156.256.166.387.046.776.08,1.166.1.487.024.975.029,1.414.041a.78.78,0,0,1,.1,1.225c-.945.2-1.941-.06-2.892.191a1.422,1.422,0,0,0-.024,1.347c1.089.454,2.308-.024,3.4.316a.769.769,0,0,1,.2.953c-.08.061-.151.163-.234.174a28.678,28.678,0,0,1-5-.055,1.153,1.153,0,0,1-.156-.385c-.025-1.764-.044-3.528-.045-5.292,0-.385.074-.771.118-1.2a4.715,4.715,0,0,1,.891-.164,29,29,0,0,1,4.147.086c.372.377.244.692.182,1.055-1.137.337-2.283.009-3.437.252"
                      transform="translate(-1017.906 -158.532)"
                      fill="#fff"
                    />
                    <path
                      id="Path_92"
                      data-name="Path 92"
                      d="M2421.243,2.64a5.7,5.7,0,0,1,2.1-.076,3.36,3.36,0,0,1,.2.721c.029.736.023,1.473.024,2.21,0,2.112,0,4.224,0,6.336a3.425,3.425,0,0,1-.167,1.51,4.228,4.228,0,0,1-2.026.062,1.28,1.28,0,0,1-.317-1.052c-.009-1.424-.007-2.849-.008-4.273,0-1.277-.005-2.554,0-3.831a3.892,3.892,0,0,1,.191-1.606"
                      transform="translate(-2182.792 -2.258)"
                      fill="#fff"
                    />
                    <path
                      id="Path_93"
                      data-name="Path 93"
                      d="M1234.969,182.953a1.828,1.828,0,0,1-1.481.021,9.45,9.45,0,0,1-.164-2.723,15.571,15.571,0,0,0-.113-2.733c-.627-.408-1.315-.1-1.92-.358a.8.8,0,0,1-.009-1.1,4.2,4.2,0,0,1,.661-.1c1.706,0,3.413-.059,5.019.032a.806.806,0,0,1,.27,1.031c-.1.06-.22.18-.349.2-.527.064-1.058.1-1.485.133a.782.782,0,0,0-.349.722c-.01,1.124,0,2.248-.016,3.372,0,.482-.039.963-.062,1.5"
                      transform="translate(-1109.919 -158.629)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="col">
            <div className="float-md-end">
              <ul className="nav">
                <li className="nav-item">
                  <Link
                    className="nav-link text-uppercase text-white fw-bold"
                    to="#"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-uppercase text-white fw-bold"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-uppercase text-white fw-bold"
                    to="#"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
