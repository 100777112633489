import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Select from 'react-select';
import AxiosInstance from '../utils/axiosInstance';
import toast from "react-hot-toast";
import { Link } from 'react-router-dom'

const Signup = () => {

    const data = {
        "highway": [
            {
                "id": "10d9660c-f7f3-9a8b-770b-65b254583b34",
                "name": "Retaining Wall1",
                "date_entered": "2024-01-25 12:29:26",
                "date_modified": "2024-02-09 12:25:42",
                "modified_user_id": "1",
                "created_by": "1",
                "description": null,
                "deleted": "0",
                "assigned_user_id": "1",
                "report_module": "cu_letting_calls_items",
                "graphs_per_row": "2"
            },
            {
                "id": "1702ee69-9e6f-b408-bcf3-65b24009ec27",
                "name": "Fire Hydrant",
                "date_entered": "2024-01-25 11:03:39",
                "date_modified": "2024-02-09 10:56:07",
                "modified_user_id": "1",
                "created_by": "1",
                "description": null,
                "deleted": "0",
                "assigned_user_id": "1",
                "report_module": "cu_letting_calls_items",
                "graphs_per_row": "2"
            }

        ],
        "building": [
            {
                "id": "1644a2bc-07a6-bbb8-7ffb-65ba49b7777f",
                "name": "Electrical55",
                "date_entered": "2024-01-31 13:20:10",
                "date_modified": "2024-01-31 13:20:10",
                "modified_user_id": "1",
                "created_by": "1",
                "description": "",
                "deleted": "0",
                "assigned_user_id": "1"
            },
            {
                "id": "1ab366ed-97e2-3f3c-02e5-65ba4972e14b",
                "name": "Plumbing",
                "date_entered": "2024-01-31 13:22:59",
                "date_modified": "2024-01-31 13:22:59",
                "modified_user_id": "1",
                "created_by": "1",
                "description": "",
                "deleted": "0",
                "assigned_user_id": "1"
            }

        ]
    }

    const subdata = [
        {
            id: "1eda9554-5742-b28c-92e2-657aea07dbd5",
            name: "Bronze",
            date_entered: "2023-12-14 11:44:22",
            date_modified: "2023-12-15 06:09:39",
            modified_user_id: "1",
            created_by: "1",
            description: null,
            deleted: "0",
            assigned_user_id: "1",
            amount: null,
            duration: null,
        },
        {
            id: "2227341f-2ea7-285b-f715-657aeaf40547",
            name: "Platinum",
            date_entered: "2023-12-14 11:45:25",
            date_modified: "2023-12-14 11:45:56",
            modified_user_id: "1",
            created_by: "1",
            description: null,
            deleted: "0",
            assigned_user_id: "1",
            amount: "0.00",
            duration: null,
        },
        {
            id: "9a9c22ff-b6f5-90e4-f613-657aead0e338",
            name: "Gold",
            date_entered: "2023-12-14 11:44:59",
            date_modified: "2023-12-14 11:44:59",
            modified_user_id: "1",
            created_by: "1",
            description: "",
            deleted: "0",
            assigned_user_id: "1",
            amount: "0.00",
            duration: "",
        },
        {
            id: "b69f1293-1499-a62b-5048-657aeab6536d",
            name: "Silver",
            date_entered: "2023-12-14 11:44:45",
            date_modified: "2023-12-14 11:44:45",
            modified_user_id: "1",
            created_by: "1",
            description: "",
            deleted: "0",
            assigned_user_id: "1",
            amount: "0.00",
            duration: "",
        },
    ];

    const countryData = [
        {
            id: "1023bed8-edeb-48ec-d096-6171066baeb2",
            name: "Alabama",
            date_entered: "2021-10-21 02:19:23",
            date_modified: "2021-10-21 02:19:23",
            modified_user_id: null,
            created_by: null,
            description: null,
            deleted: "0",
            assigned_user_id: null,
            stateid: "AL",
            statename: "Alabama",
            stateabbrev: null,
            mapicon: "alabama-map-icon.gif",
        },
        {
            id: "102422bf1-63c5-c07b-3a93-6171066bcce",
            name: "Dist. of Columbia",
            date_entered: "2021-10-21 02:19:23",
            date_modified: "2021-10-21 02:19:23",
            modified_user_id: null,
            created_by: null,
            description: null,
            deleted: "0",
            assigned_user_id: null,
            stateid: "DC",
            statename: "Dist. of Columbia",
            stateabbrev: null,
            mapicon: null,
        },
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [highway, setHighway] = useState([]);
    const [building, setBuilding] = useState([]);
    const [options, setOptions] = useState([]);
    const [workTypeList, setWorkTypeList] = useState([]);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        company_name: "",
        password: "",
        confirm_password: "",
        company_description: "",
        company_type: "3",
        worktypes: {},
        subscribe_id: "",
        state_id: []

    });
    const [errors, setErrors] = useState({});
    const [subscribe, setSubscribe] = useState([]);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        getWorkType();
        getSubscribe();
        getState();
    }, []);

    const getWorkType = () => {
        setWorkTypeList(data)
        setHighway(transformData(data.highway));
        setBuilding(transformData(data.building));
        setOptions([
            ...transformData(data.highway),
            ...transformData(data.building),
        ])
        // AxiosInstance.get(`${process.env.REACT_APP_API_URL}/get_worktype`)
        //     .then(response => {
        //          setHighway(transformData(data.highway));
        // setBuilding(transformData(data.building));
        // setOptions([
        //     ...data.highway,
        //     ...data.building,
        // ])
        //     })
        //     .catch(error => {
        //         console.log(error.message);
        //     });
    }

    const getSubscribe = () => {
        setSubscribe(subdata);
    }

    const getState = () => {
        setStateList(countryData);
    }

    const transformData = (items) =>
        items.map(({ id, name }) => ({
            value: id,
            label: name,
        })
        );


    // const handleType = (e) => {
    //     const value = parseInt(e.target.value);
    //     if (value === 1) {
    //         setOptions(highway)
    //     } else if (value === 2) {
    //         setOptions(building)
    //     } else {
    //         setOptions([
    //             ...highway,
    //             ...building,
    //         ])
    //     }
    // };

    const handleChange = (selected) => {
        const workTypeData = transformWorkTypeData(workTypeList, selected);
        setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
        setSelectedOptions(selected);
    };

    const transformWorkTypeData = (originalData, selectedValues) => {
        const selectedIds = selectedValues.map(item => item.value);

        const result = Object.keys(originalData).reduce((acc, key) => {
            acc[key] = originalData[key]
                .filter(item => selectedIds.includes(item.id))
                .map(item => item.id);
            return acc;
        }, {});

        return result;
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        // Handle "state_id" checkboxes
        if (name === "state_id") {
            setFormData((prevData) => {
                const currentIds = prevData[name] || [];
                const updatedIds = checked
                    ? [...currentIds, value] // Add the checked value
                    : currentIds.filter((id) => id !== value); // Remove the unchecked value

                return { ...prevData, [name]: updatedIds };
            });
        } else if (name === "company_type") {
            // Handle "company_type" logic
            setFormData((prevData) => ({ ...prevData, [name]: value }));

            if (value === "1") {
                setOptions(highway);
            } else if (value === "2") {
                setOptions(building);
            } else {
                setOptions([...highway, ...building]);
            }
        } else {
            // Generic handler
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }

    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name.trim()) newErrors.first_name = "First name is required.";
        if (!formData.last_name.trim()) newErrors.last_name = "Last name is required.";
        if (!formData.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format.";
        }
        if (!formData.phone_number.trim()) newErrors.phone_number = "Phone number is required.";
        else if (!/^(\+1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone_number)) {
            newErrors.phone_number = "Invalid phone number format.";
        }
        if (!formData.password.trim()) newErrors.password = "password is required.";
        if (!formData.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required.";
        if (!formData.company_name.trim()) newErrors.company_name = "Company name is required.";
        return newErrors;
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        AxiosInstance.post(`${process.env.REACT_APP_API_URL}/registration`, formData)
            .then(response => {
                if (response) {
                    toast.success("Registration successfully!");
                    setFormData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        company_name: "",
                        password: "",
                        confirm_password: "",
                        company_description: "",
                        company_type: "",
                        worktypes: {},
                        subscribe_id: "",
                        state_id: []
                    });
                } else {
                    toast.error("Something went wrong!");
                }
            })
            .catch(error => {
                toast.error("Error sending message. Please try again later.");
            });
    };



    return (
        <>
            <div className="vh-100 d-flex flex-column">
                <div className="bg-image">
                    <Header />
                    <div className="container">
                        <div className="row mt-15">
                            <div className="col col-md-auto bg-primary">
                                <h1 className="text-white p-3 fw-boldest point-42">Sign Up</h1>
                            </div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                </div>
                <div className="bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col col-md-6 bg-white py-5">
                                {/* <h2 className="fw-bold">Get Started</h2> 
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p> */}

                                <form className="px-3" onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.first_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="First name"
                                                aria-label="First name"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.last_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Last name"
                                                aria-label="Last name"
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.email ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Email"
                                                aria-label="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.phone_number ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Phone Number"
                                                aria-label="Phone Number"
                                                name="phone_number"
                                                value={formData.phone_number}
                                                onChange={handleInputChange}
                                            />
                                            {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="password"
                                                className={`form-control border border-secondary rounded-0 ${errors.password ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Password"
                                                aria-label="Password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                            />
                                            {errors.password && <div className="text-danger">{errors.password}</div>}
                                        </div>
                                        <div className="col">
                                            <input
                                                type="password"
                                                className={`form-control border border-secondary rounded-0 ${errors.confirm_password ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Confirm Password"
                                                aria-label="Confirm Password"
                                                name="confirm_password"
                                                value={formData.confirm_password}
                                                onChange={handleInputChange}
                                            />
                                            {errors.confirm_password && <div className="text-danger">{errors.confirm_password}</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className={`form-control border border-secondary rounded-0 ${errors.company_name ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Company Name"
                                                aria-label="Company Name"
                                                name="company_name"
                                                value={formData.company_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.company_name && <div className="text-danger">{errors.company_name}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <textarea
                                                placeholder="Brief Company Description"
                                                className={`form-control form-textarea border border-secondary rounded-0 ${errors.company_description ? "is-invalid" : ""
                                                    }`}
                                                name="company_description"
                                                value={formData.company_description}
                                                onChange={handleInputChange}
                                            ></textarea>
                                            {errors.company_description && <div className="text-danger">{errors.company_description}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <select
                                                defaultValue="3"
                                                className={`form-control border border-secondary rounded-0 ${errors.company_type ? "is-invalid" : ""
                                                    }`}
                                                aria-label="Default select example"
                                                onChange={handleInputChange}
                                                name="company_type"
                                            >
                                                <option value="1">Highway</option>
                                                <option value="2">Building</option>
                                                <option value="3">Both</option>
                                            </select>
                                            {errors.company_type && <div className="text-danger">{errors.company_type}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                <span className="fw-bold point-16 text-uppercase">Select Work Type</span>
                                            </label>
                                            <Select
                                                className="border border-secondary rounded-0"
                                                options={options}
                                                isMulti
                                                value={selectedOptions}
                                                onChange={handleChange}
                                                placeholder="Select work type..."
                                                isSearchable
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <h3
                                                className="text-secondary fw-bolder text-uppercase point-16"
                                            >
                                                SUBSCRIBE:
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        {subscribe.map((item) => (
                                            <div className="col" key={item.id}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input border border-1 border-secondary border-solid rounded-0 text-uppercase"
                                                        type="radio"
                                                        value={item.id}
                                                        name="subscribe_id"
                                                        id={`flexCheckDefault-${item.id}`}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`flexCheckDefault-${item.id}`}
                                                    >
                                                        <span className="fw-bold point-16">{item.name.toUpperCase()}</span>
                                                    </label>
                                                    <br />
                                                    <p className="point-16 fw-light">{item?.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                <span className="fw-bold point-16 text-uppercase"
                                                >What states are you located in?</span>
                                            </label>
                                            {/* <!-- <select
                                                className="form-select border border-secondary rounded-0"
                                                aria-label="Default select example"
                                            >
                                                <option selected>What states are you located in?</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select> --> */}
                                        </div>
                                    </div>

                                    <div className="row mb-3 g-0">
                                        {stateList.map((state) => (
                                            <div className="col col-md-4" key={state.id}>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input border border-1 border-secondary border-solid rounded-0"
                                                        id={`stateCheck-${state.id}`}
                                                        name="state_id"
                                                        value={state.id}
                                                        onChange={handleInputChange}
                                                        checked={(formData.state_id || []).includes(state.id)}
                                                    />
                                                    <label
                                                        className="form-check-label text-uppercase"
                                                        htmlFor={`stateCheck-${state.id}`}
                                                    >
                                                        {state.statename}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <p className="text-secondary fw-bolder point-16">
                                        Already have an account?
                                        <Link
                                            className="text-danger text-decoration-none"
                                            to="#"
                                        >
                                            Login
                                        </Link>
                                    </p>

                                    <button
                                        type="submit"
                                        className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18"
                                    >
                                        Create account & continue
                                    </button>

                                    <p className="mt-3 point-16 text-secondary">
                                        CC Collects And Uses Personal Data In Accordance With Our
                                        Privacy Policy. By Creating An Account, You Agree To Our
                                        <Link
                                            to="#"
                                        >
                                            Terms & Conditions
                                        </Link>.
                                    </p>
                                </form>

                            </div>
                            <div className="col col-md-6">
                                <div className="p-5">
                                    <h3 className="text-secondary fw-bold point-52">
                                        Join the Community to Search Less & Bid More!
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Signup;