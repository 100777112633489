import axios from "axios";

// Axios Interceptor Instance
const AxiosInstance = axios.create({
    baseURL: process.env.BASE_URL,
});

// Axios Interceptor: Header Method
AxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        const accessToken = token ? JSON.parse(token) : null;

        // If token is present, add it to the request's Authorization Header
        if (accessToken && config.headers) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Axios Interceptor: Response Method
AxiosInstance.interceptors.response.use(
    (response) => {
        // Can modify the response if needed
        return response;
    },
    (error) => {
        // Handle response errors here
        return Promise.reject(error);
    }
);

export default AxiosInstance;
