import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <footer className="bg-white mt-auto">
      <div className="bg-primary py-4">
        <div className="container">
          <div className="row">
            <div className="col col-md-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="94.113"
                height="108.637"
                viewBox="0 0 94.113 108.637"
              >
                <defs>
                  <clipPath id="clip-path-42">
                    <rect
                      id="Rectangle_42"
                      data-name="Rectangle 42"
                      width="94.113"
                      height="108.637"
                      fill="#9fafca"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_13"
                  data-name="Group 13"
                  transform="translate(0 -0.002)"
                >
                  <g
                    id="Group_12"
                    data-name="Group 12"
                    transform="translate(0 0.002)"
                    clipPath="url(#clip-path-42)"
                  >
                    <path
                      id="Path_47"
                      data-name="Path 47"
                      d="M31.339,59.857c.012.2.035.391.035.587q0,5.39,0,10.779v.536c-.187.13-.359.27-.548.378-2.091,1.193-4.192,2.369-6.277,3.573C22.316,77,20.1,78.318,17.869,79.618c-1.451.846-2.909,1.682-4.364,2.522L6.152,86.386c-.267.154-.537.3-.831.466a4.344,4.344,0,0,1-.69-.261c-1.4-.815-2.8-1.645-4.193-2.472C.317,84.048.2,83.964.1,83.893,0,83.2-.041,31.352.054,29.649c.14-.26.42-.348.657-.486,1.7-.985,3.4-1.959,5.09-2.947,1.644-.959,3.274-1.941,4.923-2.891,1.992-1.148,4-2.268,6-3.409q2.553-1.46,5.092-2.943c1.741-1.015,3.469-2.052,5.215-3.059,2.211-1.274,4.439-2.517,6.65-3.792,1.77-1.02,3.52-2.075,5.288-3.1,1.042-.6,2.1-1.174,3.151-1.771,1.119-.637,2.231-1.285,3.344-1.932.409-.238.813-.487,1.219-.731l.16-.034-.013.007.563.019c1.4.821,2.8,1.651,4.207,2.462,1.043.6,2.1,1.175,3.15,1.768.705.4,1.405.806,2.1,1.214.187.109.362.238.613.4-1.593,1.008-3.2,1.857-4.76,2.777-1.547.911-3.107,1.8-4.661,2.7l-4.727,2.73c-1.551.9-3.1,1.8-4.655,2.686-1.581.9-3.174,1.787-4.752,2.7-1.55.895-3.085,1.816-4.631,2.718-1.572.916-3.149,1.823-4.724,2.734l-4.663,2.692q-2.368,1.367-4.737,2.733c-1.548.895-3.108,1.772-4.645,2.7-.013.257-.033.478-.033.7q0,17.3,0,34.594c0,.2.015.392.023.588-.016.1-.009.187.128.181.274-.134.557-.252.821-.4q2.689-1.545,5.372-3.1,2.213-1.278,4.428-2.554,3.705-2.138,7.41-4.277c.845-.488,1.685-.984,2.528-1.477l.076.015Z"
                      transform="translate(0 -2.412)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_48"
                      data-name="Path 48"
                      d="M287.313,408.444c-.008-.252-.023-.5-.023-.757q0-5.095,0-10.19v-.711a1.7,1.7,0,0,1,.764-.585q3.089-1.794,6.187-3.573l7.865-4.54q2.109-1.218,4.219-2.435c1.719-1,3.432-2.008,5.154-3q3.388-1.952,6.788-3.883a1.014,1.014,0,0,1,.22-.069c.188.08.145.239.152.366.013.252.007.505.007.758q0,41.054,0,82.109c0,.362-.008.724-.013,1.116l-4.882,2.841-.577.011c-1.7-.967-3.41-1.925-5.107-2.9-1.092-.629-2.165-1.293-3.254-1.929-1.353-.791-2.714-1.569-4.072-2.352l-8.154-4.706L288,451.365q-2.619-1.512-5.237-3.026c-.186-.108-.358-.241-.536-.363.058-.082.08-.145.123-.17,1.2-.713,2.4-1.432,3.616-2.131,2.181-1.257,4.368-2.5,6.564-3.757,1.762,1.013,3.484,2,5.205,2.993s3.442,1.992,5.166,2.982,3.411,2,5.2,2.991a3.816,3.816,0,0,0,.08-.425c.088-2.017.026-52.949-.07-53.672a4.171,4.171,0,0,0-.528.213c-1.218.7-2.431,1.4-3.646,2.1l-5.239,3.023-4.363,2.519q-2.91,1.681-5.821,3.36c-.291.167-.591.318-.887.476l-.319-.034"
                      transform="translate(-266.367 -357.414)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_49"
                      data-name="Path 49"
                      d="M1035.819,215.085c.9.4,25.694,14.737,26.184,15.135v54.186c-1.066.729-9.6,5.647-10.28,5.927-.17-.067-.132-.227-.137-.355-.011-.308-.006-.617-.006-.926q0-25.853,0-51.705v-1.131c-.246-.155-.474-.312-.714-.449-1.849-1.052-3.7-2.093-5.549-3.153-1.166-.671-2.311-1.378-3.474-2.054q-3.489-2.027-6.988-4.038l-5.093-2.944q-1.745-1.008-3.49-2.015c-.238-.138-.468-.288-.739-.456a2.389,2.389,0,0,1,.293-.251c3.341-1.938,6.684-3.871,9.994-5.772"
                      transform="translate(-967.89 -202.996)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_50"
                      data-name="Path 50"
                      d="M1117.171,592.674l6.552,3.779c1.066.615,2.125,1.244,3.2,1.84a.761.761,0,0,1,.467.8c-.019.223,0,.449,0,.673q0,25.974,0,51.948c0,.364-.025.728-.038,1.092l-.092.141q-.793.461-1.586.922-2.724,1.575-5.449,3.149-1.522.879-3.046,1.753l-.192.017c-.018-.335-.053-.67-.053-1q0-32.065,0-64.129c0-.334.045-.667.069-1l.168.016"
                      transform="translate(-1054.153 -559.348)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_51"
                      data-name="Path 51"
                      d="M833.682.172l-.563-.019c.26-.2.284-.2.563.019"
                      transform="translate(-786.293 -0.002)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_52"
                      data-name="Path 52"
                      d="M832.743,1929.973l.577-.011c-.29.214-.293.214-.577.011"
                      transform="translate(-785.938 -1821.487)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_53"
                      data-name="Path 53"
                      d="M372.669,907.915l.319.034c-.216.169-.221.169-.319-.034"
                      transform="translate(-351.723 -856.885)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_54"
                      data-name="Path 54"
                      d="M830.663,2.5l-.16.034a.219.219,0,0,1,.16-.034"
                      transform="translate(-783.824 -2.358)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_55"
                      data-name="Path 55"
                      d="M1118.368,592.674l-.168-.016.168.016"
                      transform="translate(-1055.35 -559.348)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_56"
                      data-name="Path 56"
                      d="M554.984,1021.794h-.078l-.076-.015.154.015"
                      transform="translate(-523.645 -964.349)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_57"
                      data-name="Path 57"
                      d="M186.8,1228.92c-.136.006-.144-.079-.128-.181l.128.181"
                      transform="translate(-176.177 -1159.677)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_58"
                      data-name="Path 58"
                      d="M1300.678,1663.011l.092-.141-.092.141"
                      transform="translate(-1227.572 -1569.407)"
                      fill="#9fafca"
                    />
                    <path
                      id="Path_59"
                      data-name="Path 59"
                      d="M1117.9,1769.013l.192-.017c-.055.1-.117.13-.192.017"
                      transform="translate(-1055.07 -1669.568)"
                      fill="#9fafca"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="col col-md-3">
              <ul className="nav flex-column text-secondary">
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="#"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="/signup"
                  >
                    Sign Up
                  </Link>

                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="#"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col col-md-3">
              <h4 className="text-secondary point-18">Headquarters</h4>
              <p className="text-white point-16">
                2100 Southbridge Pkwy
                <br />
                Birmingham, AL 35209
                <br />
                <a
                  href="mailto:info@contractorcn.com"
                  className="text-white text-decoration-none"
                >
                  info@contractorcn.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-2">
          <div className="col point-16 fw-normal">
            © 2024 Contractors Communication Network All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}
