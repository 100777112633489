import { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles/main.scss';
import { Toaster } from "react-hot-toast";
import Home from './pages/Home';
import Signup from './pages/Signup';

function App() {
  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
          <Toaster position="top-right" />
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;